document.addEventListener("DOMContentLoaded", function(event)
{
 activateFormDocument();
 activateQuestion();
 activateService();
 addCode();
 calendar();
 changeDocument();
 changeSlide();
 changeState();
 changeUser();
 deleteCode();
 deleteDebt();
 deleteDocument();
 deleteIncome();
 deleteNote();
 deleteRelease();
 deleteSet();
 deleteSlide();
 deleteState();
 deleteTask();
 deleteUser();
 headerScroll();
 removeCode();
 scroll();
 search();
 selectSet();
 showImmovable();
 showSet();
 showUser();
 timer();
 timeSlide();
 toggleData();
 toggleEmail();
 toggleMenu();
 tooglePhone();
 validate();
});

function activateFormDocument()
{
 $('.btnFormDocument').click(function()
 {
  $('#formDocumentModal').modal('show');
 });
}

function activateQuestion()
{
 let title = document.querySelector('.modal-title');
 let description = document.querySelector('.modal-description');
 let questions = [
  ['¿Los gastos de cobro Pre Jurídico generan cobro de Honorarios?', 'Como lo indica la Superintendencia Financiera de Colombia en su Concepto del pasado 13 de junio de 2008, el artículo 1629 del Código Civil establece que los gastos que ocasiona el cobro son de cargo del deudor; por lo tanto, en general corresponde a este asumir los costos que la gestión de cobro de la obligación en mora genera, puesto que es de la naturaleza de esta etapa su carácter y contenido persuasivo, que significa el intento, por cualquier medio legalmente permitido (cartas, llamadas, visitas), por obtener el pago y evitar de esta manera acudir a las demandas judiciales ante el juez.'],
  ['¿Qué son actuaciones de cobro?', 'Las actuaciones de cobro son todas aquellas acciones, desarrolladas e implementadas para el cobro pre jurídico con el fin del recaudo de cartera morosa del acreedor. Estas podrán iniciar con una carta o notificación, física o virtual, llamadas, visitas entre otras, a cargo de la empresa o abogado contratado.'],
  ['¿Debo pagar honorarios?', 'Los gastos que ocasiona el cobro Pre Jurídico son de cargo del deudor; por lo tanto, en general corresponde a este asumir los costos que la gestión de cobro de la obligación en mora genera.'],
  ['¿La casa de cobro es contratada por asamblea general?', 'No, la ley 675 de 2001 es precisa al establecer en el Art 51 dentro de las funciones de la administración la responsabilidad del recaudo de las cuotas administrativas y de más sujetas por la Ley; en el numeral 8 el cobro de dichas obligaciones puede ser delegadas con plena facultad a un tercero POR EL ADMINISTRADOR del conjunto.'],
  ['¿Es legal que cobren honorarios?', 'Sí, puede cobrar los gastos y honorarios de cobranza Pre Jurídica, siempre y cuando realice actos encaminados a su recuperación; el solo hecho que el acreedor informe a la persona que el estado de cuenta o la factura están en manos de la oficina de cobro o el abogado.'],
  ['¿Quien supervisa los honorarios de abogados?', 'Existen dos entidades propias a la supervisión y velación por la integridad y orden jurídico nacional. Las cuales son: La superintendencia financiera: como ente y organismo de vigilancia y la Corporación Colegio Nacional de Abogados (CONALBOS): sus fines principales velar por la integridad e intangibilidad del orden jurídico nacional;  por la recta y cumplida administración de Justicia, por el aquipalado respeto a los Derechos Humanos; por la dignificación y seguridad social de quienes ejercen la profesión de abogados, dentro de los marcos de los más altos niveles morales, éticos, jurídicos, sociales, intelectuales, culturales y económicos.'],
  ['¿Qué es cobro Pre Jurídico?', 'Se entiende como cobro pre-Jurídico, toda actividad tendiente a la recuperación de una obligación sin que medie un proceso jurídico, es decir, es un cobro extraprocesal donde se procura persuadir al deudor y advertirle sobre las implicaciones que traería para el llegar a la etapa de cobro jurídico.'],
  ['¿Qué es cobro Jurídico?', 'Es el procedimiento que generalmente le sigue a un cobro coactivo, este último que consiste en una serie de llamadas solicitando el pago de manera extrajurídica por parte del acreedor, o las personas que el mismo haya encomendado para esta labor.']
 ];

 questions.forEach(showModalQuestion)

 function showModalQuestion(item, index, arr)
 {
  $('#question'+(index+1)).click(function()
  {
   $('#questionModal').modal('show');

   title.textContent = arr[index][0];
   description.textContent = arr[index][1];
  });
 }
}

function activateService()
{
 let title = document.querySelector('.modal-title');
 let description = document.querySelector('.modal-description');
 let questions = [
  ['Formación Cultural', '<img class="img-fluid" src="/img/modals/modal-1.png">'],
  ['Formación en la Ley 675 de 2001', '<img class="img-fluid" src="/img/modals/modal-2.png">'],
  ['Formación en la Comunidad', '<img class="img-fluid" src="/img/modals/modal-3.png">'],
  ['Formación en Convivencia', '<img class="img-fluid" src="/img/modals/modal-4.png">']
 ];

 questions.forEach(showModalQuestion)

 function showModalQuestion(item, index, arr)
 {
  $('#service'+(index+1)).click(function()
  {
   $('#serviceModal').modal('show');

   description.innerHTML = arr[index][1];
  });
 }
}

function addCode()
{
 $('#btnRemove').attr('disabled', 'disabled');

 $('#btnAdd').click(function()
 {
  let quantity = $('.cloneCode').length;
  let id = new Number(quantity + 1);
  let box = $('#box');
  let boxCode = $('#boxCode' + quantity).clone().attr('id', 'boxCode' + id);

  boxCode.children(':first').attr('id', 'inputCode' + id).attr('name', 'inputCode' + id).attr('required', 'true');

  $(box).append(boxCode);

  $("#btnRemove").removeAttr('disabled');

  if(id==300){
   $('#btnAdd').attr('disabled', 'disabled');
  }

 });
}

function calendar()
{
 $(".readonly").keydown(function(e){
  e.preventDefault();
 });

 $('.calendar').datetimepicker({
  allowInputToggle: true,
  useCurrent: false,
  ignoreReadonly: true,
  format: 'MMMM Y',
  widgetPositioning:{
   horizontal: 'auto',
   vertical: 'auto'
  }
 });

 $('.month').datetimepicker({
  allowInputToggle: true,
  useCurrent: false,
  ignoreReadonly: true,
  format: 'MMMM',
  widgetPositioning:{
   horizontal: 'auto',
   vertical: 'auto'
  }
 });

 $('.task').datetimepicker({
  allowInputToggle: true,
  useCurrent: false,
  ignoreReadonly: true,
  format: 'Y-MM-DD hh:mm:ss',
  widgetPositioning:{
   horizontal: 'auto',
   vertical: 'auto'
  }
 });

 $('.year').datetimepicker({
  allowInputToggle: true,
  useCurrent: false,
  ignoreReadonly: true,
  format: 'Y',
  widgetPositioning:{
   horizontal: 'auto',
   vertical: 'auto'
  }
 });
}

function changeDocument()
{
 $(document).on('click', '.changeDocument', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de este documento?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo podría aparecer o no en la respectiva sección</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/document/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: "POST",
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h5>¡Buen Trabajo!</h5>",
        text: "<h6 class='text-center'>El documento seleccionado ha sido activado.</h6>",
        type: "success"
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h5>¡Atención!</h5>",
        text: "<h6 class='text-center'>El documento seleccionado ha sido desactivado.</h6>",
        type: "warning"
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del documento porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del documento porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del documento porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del documento porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del documento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del documento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado del documento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del documento porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El documento seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }

   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function changeSlide()
{
 $(document).on('click', '.changeSlide', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de este slide?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo podría aparecer o no al inicio</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/slide/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: "POST",
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h5>¡Buen Trabajo!</h5>",
        text: "<h6 class='text-center'>El slide seleccionado ha sido activado.</h6>",
        type: "success"
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h5>¡Atención!</h5>",
        text: "<h6 class='text-center'>El slide seleccionado ha sido desactivado.</h6>",
        type: "warning"
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del slide porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del slide porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del slide porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del slide porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del slide, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del slide, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado del slide, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del slide porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El slide seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function changeState()
{
 $(document).on('click', '.changeState', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar este estado?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo podría aparecer o no al momento de crear o editar una novedad o un inmueble.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/state/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: "POST",
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h5>El estado seleccionado ha sido activado.</h5>",
        text: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        type: "success"
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h5>El estado seleccionado ha sido desactivado.</h5>",
        text: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        type: "warning"
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha actualizado el estado, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El estado seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function changeUser()
{
 $(document).on('click', '.changeUser', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas cambiar el estado de este usuario?</h5>",
   text: "<h6 class='text-justify'>Al hacerlo podría negarle o aprobarle el acceso al sistema.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/user/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: "POST",
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h5>¡Buen Trabajo!</h5>",
        text: "<h6 class='text-center'>El usuario seleccionado ha sido activado.</h6>",
        type: "success"
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h5>¡Atención!</h5>",
        text: "<h6 class='text-center'>El usuario seleccionado ha sido desactivado.</h6>",
        type: "warning"
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-center'>No se ha actualizado el estado del usuario porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-center'>No se ha actualizado el estado del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-center'>No se ha actualizado el estado del usuario porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-center'>No se ha actualizado el estado del usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-center'>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-center'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-center'>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-center'>No se ha actualizado el estado del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El usuario seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteCode()
{
 $(document).on('click', '.deleteCode', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar esta código?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/code/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>El código seleccionado ha sido eliminado.</h5>",
       text: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el código porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el código porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el código porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el código porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado el código, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el código, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el código, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el código porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El código seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteDebt()
{
 $(document).on('click', '.deleteDebt', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar esta deuda?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/debt/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>La deuda seleccionada ha sido eliminada.</h5>",
       text: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la deuda porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la deuda porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la deuda porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la deuda porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado la deuda, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la deuda, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado la deuda, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la deuda porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>La deuda seleccionada no ha sido afectada</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteDocument()
{
 $(document).on('click', '.deleteDocument', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este documento?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/document/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>El documento seleccionado ha sido eliminado.</h5>",
       text: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el documento porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el documento porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el documento porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el documento porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado el documento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el documento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el documento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el documento porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El documento seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteIncome()
{
 $(document).on('click', '.deleteIncome', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este recaudo?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/income/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>¡Buen Trabajo!</h5>",
       text: "<h6 class='text-center'>El recaudo seleccionado ha sido eliminado.</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el recaudo porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el recaudo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el recaudo porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el recaudo porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado el recaudo, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el recaudo, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el recaudo, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el recaudo porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El recaudo seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteNote()
{
 $(document).on('click', '.deleteNote', function()
 {
  let id = $(this).attr('id');

  $.ajax({
   url: "/note/"+id,
   headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
   type: 'DELETE',
   data: { id },
   success: function(data)
   {
    swal({
     html: true,
     title: "<h5>¡Buen Trabajo!</h5>",
     text: "<h6 class='text-center'>La nota seleccionada ha sido eliminada.</h6>",
     type: "success"
    })
   },
   error: function(jqXHR, textStatus, errorThrown)
   {
    if(jqXHR.status===0)
    {
     swal({
      html: true,
      title: "<h5>Atención</h5>",
      text: "<h6 class='text-justify'>No se ha eliminado la nota porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==404)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se ha eliminado la nota porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==405)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se ha eliminado la nota porque no se ha encontrado la página de respuesta.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==500)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se ha eliminado la nota porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='parsererror')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado la nota, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='timeout')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la nota, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='abort')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado la nota, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else
    {
     swal({
      html: true,
      title: "<h5>Atención</h5>",
      text: "<h6 class='text-justify'>No se ha eliminado la nota porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
   }
  });

  setTimeout(function(){ window.location.reload(); }, 1500);
 });
}

function deleteRelease()
{
 $(document).on('click', '.deleteRelease', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar esta novedad?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/release/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>¡Buen Trabajo!</h5>",
       text: "<h6 class='text-center'>La novedad seleccionada ha sido eliminada.</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la novedad porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la novedad porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la novedad porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la novedad porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado la novedad, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la novedad, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado la novedad, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado la novedad porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>La novedad seleccionada no ha sido afectada</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteSet()
{
 $(document).on('click', '.deleteSet', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas desactivar este conjunto?</h5>",
   text: "<h6 class='text-center'>Al hacerlo podría aparecer o no al momento de crear nuevos inmuebles.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/set/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        html: true,
        title: "<h5>¡Buen Trabajo!</h5>",
        text: "<h6 class='text-center'>El conjunto seleccionado ha sido activado.</h6>",
        type: "success"
       })
      }
      else
      {
       swal({
        html: true,
        title: "<h5>¡Atención!</h5>",
        text: "<h6 class='text-center'>El conjunto seleccionado ha sido desactivado.</h6>",
        type: "warning"
       })
      }
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del conjunto porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del conjunto porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del conjunto porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del conjunto porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del conjunto, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del conjunto, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha actualizado el estado del conjunto, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha actualizado el estado del conjunto porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El conjunto seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteSlide()
{
 $(document).on('click', '.deleteSlide', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este slide?</h5>",
   text: "<h6 class='text-center'>¡Esta acción es irreversible!</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/slide/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>¡Buen Trabajo!</h5>",
       text: "<h6 class='text-center'>El slide seleccionado ha sido eliminado.</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el slide porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el slide porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el slide porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el slide porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado el slide, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: 'El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el slide, por favor vuelve a intentarlo.',
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el slide, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el slide porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El slide seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteState()
{
 $(document).on('click', '.deleteState', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este estado?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/state/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>El estado seleccionado ha sido eliminado.</h5>",
       text: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el estado porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el estado porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el estado porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el estado porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado el estado, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el estado, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el estado, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el estado porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El estado seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteTask()
{
 $(document).on('click', '.deleteTask', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este evento?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/task/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>El evento seleccionado ha sido eliminado.</h5>",
       text: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el evento porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el evento porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el evento porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el evento porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado el evento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el evento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el evento, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el evento porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El evento seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }

   setTimeout(function(){ redirectTo() }, 1500);
  });
 });
}

function deleteUser()
{
 $(document).on('click', '.deleteUser', function()
 {
  let id = $(this).attr('id');

  swal({
   html: true,
   title: "<h5>¿Realmente deseas eliminar este usuario?</h5>",
   text: "<h6 class='text-center'>Esta acción es irreversible</h6>",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/user/"+id,
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'DELETE',
     data: { id },
     success: function(data)
     {
      swal({
       html: true,
       title: "<h5>¡Buen Trabajo!</h5>",
       text: "<h6 class='text-center'>El usuario seleccionado ha sido eliminado.</h6>",
       type: "success"
      })
     },
     error: function(jqXHR, textStatus, errorThrown)
     {
      if(jqXHR.status===0)
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el usuario porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==404)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==405)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el usuario porque no se ha encontrado la página de respuesta.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(jqXHR.status==500)
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='parsererror')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se ha eliminado el usuario, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='timeout')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el usuario, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else if(textStatus==='abort')
      {
       swal({
        html: true,
        title: "<h5>Error</h5>",
        text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se ha eliminado el usuario, por favor vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
      else
      {
       swal({
        html: true,
        title: "<h5>Atención</h5>",
        text: "<h6 class='text-justify'>No se ha eliminado el usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
        type: "error",
        confirmButtonClass: "btn-warning"
       });
      }
     }
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h5>Sin Cambios</h5>",
     text: "<h6 class='text-center'>El usuario seleccionado no ha sido afectado</h6>",
     type: "success",
     confirmButtonClass: "btn-success"
    });

    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function headerScroll()
{
 $(window).scroll(function()
 {
  if($(this).scrollTop()==0)
   $('#header_fixed').css('background-color', 'rgba(0, 0, 0, 0.2)');
  else
   $('#header_fixed').css('background-color', 'rgba(0, 0, 0, 0.7)');
 });
}

function removeCode()
{
 $('#btnRemove').attr('disabled', 'disabled');

 $('#btnRemove').click(function()
 {
  let quantity = $('.cloneCode').length;

  $('#boxCode' + quantity).remove();
  $("#btnAdd").removeAttr('disabled');

  if(quantity-1==1) $('#btnRemove').attr('disabled', 'disabled');
 });
}

function redirectTo()
{
 window.location = "/task";
}

function scroll()
{
 $(window).scroll(function()
 {
  if($(this).scrollTop()>100)
   $(".scroll-top").fadeIn();
  else
   $(".scroll-top").fadeOut();
 });

 $(".scroll-top").click(function()
 {
  $("html, body").animate({ scrollTop: 0 }, 600);

  return false;
 });
}

function search()
{
 $('.select_account').select2();
 $('.select_administrator').select2();
 $('.select_mandated').select2();
 $('.select_propietor').select2();
 $('.select_report').select2();
 $('.select_rol').select2();
 $('.select_set').select2();
 $('.select_state').select2();
 $('.select_type').select2();
}

function selectSet()
{
 $(".filter").addClass("d-none");

 $(".set").change(function()
 {
  $(".filter").removeClass("d-none");
 });

 $(".set").keyup(function()
 {
  $(".filter").removeClass("d-none");
 });
}

function showImmovable()
{
 $(document).on('click', '.showImmovable', function()
 {
  let id = $(this).attr('id');

  $.ajax({
   url: '/immovable/'+id,
   success: function(immovable)
   {
    swal({
     html: true,
     title: immovable.set,
     text:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Conjunto: '+immovable.set+'</p>'
      +' '+
      '<p class="m-0 small">Torre: '+immovable.town+'</p>'
      +' '+
      '<p class="m-0 small">Apartamento: '+immovable.apartment+'</p>'
      +' '+
      '<p class="m-0 small">Estado: '+immovable.status+'</p>'
      +' '+
      '<p class="m-0 small">Nombre Propietario: '+immovable.name_proprietor+'</p>'
      +' '+
      '<p class="m-0 small">N° Identificación Propietario: '+immovable.identifier_proprietor+'</p>'
      +' '+
      '<p class="m-0 small">N° Teléfono Propietario: '+immovable.phone_proprietor+'</p>'
      +' '+
      '<p class="m-0 small">Email Propietario: '+immovable.email_proprietor+'</p>'
      +' '+
      '<p class="m-0 small">Nombre Encargado: '+immovable.name_mandated+'</p>'
      +' '+
      '<p class="m-0 small">N° Identificación Encargado: '+immovable.identifier_mandated+'</p>'
      +' '+
      '<p class="m-0 small">N° Teléfono Encargado: '+immovable.phone_mandated+'</p>'
      +' '+
      '<p class="m-0 small">Email Encargado: '+immovable.email_proprietor+'</p>'
      +' '+
      '<p class="m-0 small">Descripción: '+immovable.description+'</p>'
      +' '+
      '<p class="m-0 small">Creado Por: '+immovable.user+'</p>'
      +' '+
      '</div>',
     type: "success",
     confirmButtonClass: 'btn-success',
     confirmButtonText: 'Cerrar',
     closeOnConfirm: false
    })
   },
   error: function(jqXHR, textStatus, errorThrown)
   {
    if(jqXHR.status===0)
    {
     swal({
      html: true,
      title: "<h5>Atención</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del inmueble porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==404)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del inmueble porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==405)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del inmueble porque no se ha encontrado la página de respuesta.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==500)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del inmueble porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='parsererror')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se han recuperado los datos del inmueble, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='timeout')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del conjunto, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='abort')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se han recuperado los datos del inmueble, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else
    {
     swal({
      html: true,
      title: "<h5>Atención</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del inmueble porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
   }
  });
 });
}

function showSet()
{
 $(document).on('click', '.showSet', function()
 {
  let id = $(this).attr('id');

  $.ajax({
   url: '/set/'+id,
   success: function(set)
   {
    swal({
     html: true,
     title: set.name,
     text:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Dirección: '+set.address+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono 1: '+set.phone_1+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono 2: '+set.phone_2+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono 3: '+set.phone_3+'</p>'
      +' '+
      '<p class="m-0 small">Administrador: '+set.administrator+'</p>'
      +' '+
      '<p class="m-0 small">Nit: '+set.nit+'</p>'
      +' '+
      '<p class="m-0 small">Email 1: '+set.email_1+'</p>'
      +' '+
      '<p class="m-0 small">Email 2: '+set.email_2+'</p>'
      +' '+
      '<p class="m-0 small">Email 3: '+set.email_3+'</p>'
      +' '+
      '<p class="m-0 small">Número Cuenta: '+set.number+'</p>'
      +' '+
      '<p class="m-0 small">Institucion o Banco: '+set.institution+'</p>'
      +' '+
      '<p class="m-0 small">Tipo Cuenta: '+set.type+'</p>'
      +' '+
      '<p class="m-0 small">Descripción: '+set.description+'</p>'
      +' '+
      '<p class="m-0 small">Creado por: '+set.user+'</p>'
      +' '+
      '</div>',
     type: "success",
     confirmButtonClass: 'btn-success',
     confirmButtonText: 'Cerrar',
     closeOnConfirm: false
    })
   },
   error: function(jqXHR, textStatus, errorThrown)
   {
    if(jqXHR.status===0)
    {
     swal({
      html: true,
      title: "<h5>Atención</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del conjunto porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==404)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del conjunto porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==405)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del conjunto porque no se ha encontrado la página de respuesta.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==500)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del conjunto porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='parsererror')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se han recuperado los datos del conjunto, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='timeout')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del conjunto, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='abort')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se han recuperado los datos del conjunto, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else
    {
     swal({
      html: true,
      title: "<h5>Atención</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del conjunto porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
   }
  });
 });
}

function showUser()
{
 $(document).on('click', '.showUser', function()
 {
  let id = $(this).attr('id');

  $.ajax({
   url: '/user/'+id,
   success: function(user)
   {
    swal({
     html: true,
     title: user.name,
     text:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Cedula: '+user.cedula+'</p>'
      +' '+
      '<p class="m-0 small">Email: '+user.email+'</p>'
      +' '+
      '<p class="m-0 small">N° Teléfono: '+user.phone+'</p>'
      +' '+
      '<p class="m-0 small">Dirección: '+user.address+'</p>'
      +' '+
      '<p class="m-0 small">Typo: '+user.rol+'</p>'
      +' '+
      '<p class="m-0 small">Creado por: '+user.user_create+'</p>'
      +' '+
      '<p class="m-0 small">Estado: '+user.state+'</p>'
      +' '+
      '</div>',
     type: "success",
     confirmButtonClass: 'btn-success',
     confirmButtonText: 'Cerrar',
     closeOnConfirm: false
    })
   },
   error: function(jqXHR, textStatus, errorThrown)
   {
    if(jqXHR.status===0)
    {
     swal({
      html: true,
      title: "<h5>Atención</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del usuario porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==404)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==405)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del usuario porque no se ha encontrado la página de respuesta.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(jqXHR.status==500)
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='parsererror')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>No se ha hecho la petición de forma correcta. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='timeout')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else if(textStatus==='abort')
    {
     swal({
      html: true,
      title: "<h5>Error</h5>",
      text: "<h6 class='text-justify'>La petición enviada ha sido rechazada por el servidor. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
    else
    {
     swal({
      html: true,
      title: "<h5>Atención</h5>",
      text: "<h6 class='text-justify'>No se han recuperado los datos del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h6>",
      type: "error",
      confirmButtonClass: "btn-warning"
     });
    }
   }
  });
 });
}

function store(document_id, arrayCode, quantity)
{
 $.ajax({
  url: `/code`,
  headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
  type: 'post',
  data: { document_id, arrayCode, quantity },
  success: function(data)
  {
   setTimeout(function()
   {
    swal({
     title: "¡Correcto!",
     text: 'Los códigos se han creado exitosamente.',
     type: "success",
     confirmButtonText: "OK",
     confirmButtonClass: 'btn-success'
    }, 0);
   });

   for(var i=1;i<=quantity;i++)
    $('#inputCode'+i).val('');

   $('#btnValidate').attr("disabled", false);
   setTimeout(function(){ window.location.href = `/code/${document_id}`; }, 1500);
  },
  error: function(jqXHR, textStatus, errorThrown)
  {
   if(jqXHR.status === 0)
   {
    swal({
     html: true,
     title: "<h6 class='text-center'>¡Error!</h6>",
     text: '<h5>No se han guardado los códigos porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.</h5>',
     type: 'error',
     confirmButtonClass: 'btn-outline-danger'
    });
   }
   else if(jqXHR.status == 404)
   {
    swal({
     html: true,
     title: "<h6 class='text-center'>¡Error!</h6>",
     text: '<h5>No se han guardado los códigos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>',
     type: 'error',
     confirmButtonClass: 'btn-outline-danger'
    });
   }
   else if(jqXHR.status == 500)
   {
    swal({
     html: true,
     title: "<h6 class='text-center'>¡Error!</h6>",
     text: '<h5>No se han guardado los códigos porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>',
     type: 'error',
     confirmButtonClass: 'btn-outline-danger'
    });
   }
   else if(textStatus === 'parsererror')
   {
    swal({
     html: true,
     title: "<h6 class='text-center'>¡Error!</h6>",
     text: '<h5>No se han enviado los datos de forma correcta. No se han guardado los códigos, por favor vuelve a intentarlo.</h5>',
     type: 'error',
     confirmButtonClass: 'btn-outline-danger'
    });
   }
   else if(textStatus === 'timeout')
   {
    swal({
     html: true,
     title: "<h6 class='text-center'>¡Error!</h6>",
     text: '<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han guardado los códigos, por favor vuelve a intentarlo.</h5>',
     type: 'error',
     confirmButtonClass: 'btn-outline-danger'
    });
   }
   else if(textStatus === 'abort')
   {
    swal({
     html: true,
     title: "<h6 class='text-center'>¡Error!</h6>",
     text: '<h5>La petición enviada ha sido rechazada por el servidor. No se han guardado los códigos, por favor vuelve a intentarlo.</h5>',
     type: 'error',
     confirmButtonClass: 'btn-outline-danger'
    });
   }
   else
   {
    swal({
     html: true,
     title: "<h6 class='text-center'>Atención</h6>",
     text: '<h5>No se han guardado los códigos porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>',
     type: 'error',
     confirmButtonClass: 'btn-outline-warning'
    });
   }

   for(var i=1;i<=quantity;i++)
   {
    $('#inputCode'+i).val('');
   }

   $('#btnValidate').attr("disabled", false);
   setTimeout(function(){ window.location.reload(); }, 1500);
  }
 });
}

function timer()
{
 let url = '/session';
 let minutes = 720;

 fetch(url)
  .then(function(response){
   if(response.ok){
    return response.text();
   }
  })
  .catch(function(error)
  {
   swal({
    html: true,
    title: "<h6 class='text-center'>Atención</h6>",
    text: "<h5 class='text-center'>Por favor revise su conexión a internet.</h5>",
    type: "error",
    confirmButtonClass: 'btn-outline-warning'
   });
  })
  .then(function(data)
  {
   if(data!='No logueado')
   {
    let number = 60000*minutes;

    setTimeout( function()
    {
     swal({
      html: true,
      title: "<h6 class='text-center'>Atención</h6>",
      text: "<h5 class='text-center'>Su sesión ha caducado, por favor vuelva a loguearse.</h5>",
      type: "error",
      confirmButtonClass: 'btn-outline-warning'
     });

     location.href = '/logout';
    }, number);
   }
  });
}

function timeSlide()
{
 $('.carousel').carousel({
  interval: 3500
 })
}

function toggleData()
{
 $(document).on('click', '.btn-collapse', function()
 {
  let id = $(this).attr('id');

  $("."+this.id).toggleClass("d-none");
 });
}

function toggleEmail()
{
 $(document).on('click', '.btn-collapse-email', function()
 {
  $('.torre').toggleClass("d-none");
  $('.apartamento').toggleClass("d-none");
  $('.propietario').toggleClass("d-none");
  $('.estado').toggleClass("d-none");
  $('.deuda').toggleClass("d-none");
  $('.correos').toggleClass("d-none");
  $('.opciones').toggleClass("d-none");
 });
}

function toggleMenu()
{
 $('.btnMenuCollapse').on('click', function()
 {
  $('.sidebar').toggleClass('sidebar__on');
  $('.main__container').removeClass('d-none');
 });

 $('.btnMenuNoCollapse').on('click', function()
 {
  $('.sidebar').toggleClass('sidebar__on');
  $('.main__container').addClass('d-none');
 });
}

function tooglePhone()
{
 $(document).on('click', '.btn-collapse-phone', function()
 {
  $('.torre').toggleClass("d-none");
  $('.apartamento').toggleClass("d-none");
  $('.propietario').toggleClass("d-none");
  $('.estado').toggleClass("d-none");
  $('.deuda').toggleClass("d-none");
  $('.telefonos').toggleClass("d-none");
  $('.opciones').toggleClass("d-none");
 });
}

function validate()
{
 $('#btnValidate').click(function()
 {
  let document_id = $('#document').val();
  let arrayOrigin = [];
  let arrayCode = [];
  let quantity = $('.cloneCode').length;

  for(var i=1;i<=quantity;i++)
  {
   if($('#inputCode'+i).val()==null || $('#inputCode'+i).val()=='')
   {
    swal({
     html: true,
     title: "<h6 class='text-center'>¡Advertencia!</h6>",
     text: '<h5>Debe ingresar un valor para cada código agregado.</h5>',
     type: 'warning',
     confirmButtonClass: 'btn-outline-warning'
    });

    $('#inputCode'+i).focus();

    return false;
   }
  }

  for(var i=1;i<=quantity;i++){
   arrayOrigin.push($('#inputCode'+i).val());
  }

  arrayCode = arrayOrigin.filter(function(item, index, array){
   return array.indexOf(item) === index;
  })

  quantity = arrayCode.length;

  store(document_id, arrayCode, quantity);
 });
}
