window._ = require('lodash');

try
{
 window.$ = window.jQuery = require('jquery');
 window.jQuery_Easing = require('./jquery-easing/jquery.easing.min.js');
 window.Popper = require('popper.js').default;
 window.Bootstrap = require('bootstrap');
 window.Bootstrap_Select = require('./select/select.min.js');
 window.Moment = require('./moment/moment.min.js');
 window.Datatables = require('./dataTables/datatables.min.js');
 window.Datatables_Bootstrap = require('./dataTables/dataTables.bootstrap.min.js');
 window.Datetimepicker = require('./datetimepicker/bootstrap-datetimepicker.min.js');
 window.Select = require('./select/select.min.js');
 window.Chart = require('./chart/chart.min.js');
 window.Chart_Datalabels = require('./chart/chartjs-plugin-datalabels.min.js');
 window.Sweetalert = require('sweetalert-ci-dev');
 window.Sb_Admin = require('./sb-admin/sb-admin-2.min.js');
 window.Base = require('./other/methods.js');
}
catch(e){}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if(token){
 window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}
else{
 console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
